$base-color: #c6538c;
$puf_yellow: #ffe73d;
$puf_blue: #00a1ed;
$puf_deep_blue: #1a9bf7;
$puf_red: #fc164d;
$puf_gray: #a3a3a3;
$puf_gray2: #818181;
$puf_green: #45d642;
$puf_violet: #cd1afb;
$puf_orange: #f78b3d;

.blue {
    color: $puf_blue;
}
.black {
    color: black;
}
.yellow {
    color: $puf_yellow;
}
