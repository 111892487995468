@import "styles/colors.scss";

.infoContainer {
}

.badgesContainer {
	flex: 0.5;
}

.officialEvent {
	background-color: $puf_yellow;
	font-style: italic;
	font-weight: 200;
}
.publicEvent {
	background-color: $puf_blue;
	font-style: italic;
	font-weight: 200;
}

.registerButton {
	background-color: $puf_blue;
	flex: 0.9;
	padding: 2px;
	font-size: 20px;
	color: black;
	border-radius: 20vw;
	border-width: 0px;
	font-weight: bold;
	letter-spacing: 2px;
}

.sponsors {
	padding-left: 20px;
}

.yellowBorder {
	border-style: solid;
	border-color: $puf_yellow;
	border-width: 2px;
}
