@import "styles/colors.scss";

.vContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.hContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
}
.hSimpleContainer {
    display: flex;
    flex-direction: row;
}

.alignCenter {
    align-items: center;
}
.alignCenter {
    align-items: center;
}
.alignStart {
    align-items: flex-start;
}
.alignEnd {
    align-items: flex-end;
}
.flexCenter {
    justify-content: center;
}

.flexStart {
    justify-content: flex-start;
}
.flexEnd {
    justify-content: flex-end;
}

.simplePadding {
    padding: 10px;
}
.smallPadding {
    padding: 5px;
}

.simplePaddingRight {
    padding-right: 10px;
}
.simplePaddingLeft {
    padding-left: 10px;
}
.smallPaddingRight {
    padding-right: 5px;
}
.smallPaddingLeft {
    padding-left: 5px;
}

.relative {
    position: relative;
}

.flexSimpleMargin {
    flex: 0.9;
}

.selfAlignStart {
    align-self: flex-start;
}
.selfAlignEnd {
    align-self: flex-end;
}
.selfJustifyStart {
    justify-self: flex-start;
}
.selfJustifyEnd {
    justify-self: flex-end;
}

.spaceBetween {
    justify-content: space-between;
}
.spaceEvenly {
    justify-content: space-evenly;
}
.spaceAround {
    justify-content: space-around;
}

.activeTab {
    padding-bottom: 10px;
    border-bottom-width: 5px;
    border-bottom-color: $puf_blue;
    border-bottom-style: solid;
    color: $puf_blue;
    flex: 1;
    font-size: 12px;
    font-family: SFBold;
}

.tab {
    padding-bottom: 10px;
    border: blue;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
    flex: 1;
    font-size: 12px;
    font-family: SFBold;
}

.button {
    background-color: $puf_blue;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    color: black;
    border-radius: 20vw;
    border-width: 0px;
}

.space {
    height: 10px;
}
.smallSpace {
    height: 5px;
}

.hSpace {
    width: 10px;
}
.hSmallSpace {
    width: 10px;
}

.fontSize {
    font-size: 14px;
}

.fontSizeBig {
    font-size: 16px;
}
.fontSizeSmall {
    font-size: 12px;
}

.SFBold {
    font-family: SFBold;
}
.SFRegular {
    font-family: SFRegular;
}

.textCenter {
    text-align: center;
}
